var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('details-button',{staticClass:"q-mx-sm",attrs:{"tooltip_content":_vm.getInfoText(),"name":'registration_fields_info'}}),_c('q-list',_vm._l((_vm.instance.fields),function(field,index){return _c('q-item',{key:field.id},[_c('q-item-section',[_c('div',{staticClass:"row settings-wrapper"},[_c('div',{staticClass:"col-12 col-sm-9 col-md-10 row items-center q-my-sm",class:_vm.$q.screen.gt.xs ? 'q-pr-md' : ''},[_c('div',{staticClass:"full-width"},[(field.mapping.field_type.includes('PHONE'))?_c('q-field',{attrs:{"stack-label":"","borderless":"","label":field.mapping.label,"disable":!field.enabled,"error":_vm.rti_validation_errors[field.mapping.label] ? true : false,"error-message":_vm.rti_validation_errors[field.mapping.label],"hint":field.mapping.field_type,"indicator-color":"primary","active-color":"primary"}},[_c('vue-tel-input',_vm._b({staticClass:"full-width q-pa-sm",class:_vm.$q.screen.lt.sm ? 'small-view' : '',attrs:{"value":field.enabled ? field.prepopulated_value : '',"validCharactersOnly":true},on:{"input":function($event){return _vm.updateField($event, 'prepopulated_value', index)}}},'vue-tel-input',_vm.bindProps,false))],1):(!field.mapping.field_type.includes('SELECT'))?_c('q-input',{attrs:{"value":field.enabled ? field.prepopulated_value : '',"label":field.mapping.label,"type":field.mapping.field_type.includes('PHONE') ? 'tel' : 'text',"disable":!field.enabled,"hint":field.mapping.field_type,"error":_vm.rti_validation_errors[field.mapping.label] ? true : false,"error-message":_vm.rti_validation_errors[field.mapping.label]},on:{"input":function($event){return _vm.updateField($event, 'prepopulated_value', index)}}}):_vm._e(),(field.mapping.field_type.includes('SELECT'))?_c('q-select',{attrs:{"value":field.enabled
									? typeof field.prepopulated_value === 'string'
										? JSON.parse(field.prepopulated_value)
										: field.prepopulated_value
									: '',"label":field.mapping.label,"multiple":field.mapping.field_type === 'MULTISELECT',"disable":!field.enabled,"options":field.mapping.options,"hint":field.mapping.field_type,"option-label":(item) => item.value,"clearable":""},on:{"input":function($event){return _vm.updateField($event, 'prepopulated_value', index)}}}):_vm._e()],1)]),_c('div',{staticClass:"column col-12 col-sm-3 col-md-2 items-end q-my-sm",class:_vm.$q.screen.gt.xs ? 'q-pl-md' : '',style:(_vm.$q.screen.gt.xs ? 'border-left:1px solid #ddd;' : '')},[_c('div',{staticClass:"flex"},[_c('q-checkbox',{attrs:{"value":field.enabled,"label":"Enabled","left-label":"","disable":field.mapping.field_type.includes('SELECT') &&
									field.mapping.options.length == 0},on:{"input":function($event){return _vm.updateField($event, 'enabled', index)}}},[(field.mapping.field_type.includes('SELECT') &&
										field.mapping.options.length == 0)?_c('q-tooltip',{attrs:{"anchor":"top middle","delay":500}},[_vm._v(" There are no available options for this field ")]):_vm._e(),_c('br')],1)],1),_c('div',{staticClass:"flex"},[_c('q-checkbox',{attrs:{"value":field.required,"label":"Required","disable":!field.enabled ||
									(
										_vm.instance.registration_type === 'SMS' &&
										!field.mapping.field_type.toLowerCase().includes('sms')) ||
									(
										field.mapping.field_type.includes('SELECT') &&
										field.mapping.options.length == 0
									),"left-label":""},on:{"input":function($event){return _vm.updateField($event, 'required', index)}}},[(
										field.mapping.field_type.includes('SELECT') &&
										field.mapping.options.length == 0
									)?_c('q-tooltip',{attrs:{"anchor":"top middle","delay":500}},[_vm._v(" There are no available options for this field ")]):_vm._e()],1)],1)])])])],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }