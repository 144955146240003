import { render, staticRenderFns } from "./RegistrationFields.vue?vue&type=template&id=f17a75fa&"
import script from "./RegistrationFields.vue?vue&type=script&lang=js&"
export * from "./RegistrationFields.vue?vue&type=script&lang=js&"
import style0 from "./RegistrationFields.vue?vue&type=style&index=0&id=f17a75fa&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QField from 'quasar/src/components/field/QField.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import qInstall from "../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QList,QItem,QItemSection,QField,QInput,QSelect,QCheckbox,QTooltip});
