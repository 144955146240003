<template>
	<div>
		<details-button
			class="q-mx-sm"
			:tooltip_content="getInfoText()"
			:name="'registration_fields_info'"
		/>
		<q-list>
			<q-item v-for="(field, index) in instance.fields" :key="field.id">
				<q-item-section>
					<div class="row settings-wrapper">
						<div
							class="col-12 col-sm-9 col-md-10 row items-center q-my-sm"
							:class="$q.screen.gt.xs ? 'q-pr-md' : ''"
						>
							<div class="full-width">
								<!-- PHONE -->
								<q-field
									v-if="field.mapping.field_type.includes('PHONE')"
									stack-label
									borderless
									:label="field.mapping.label"
									:disable="!field.enabled"
									:error="rti_validation_errors[field.mapping.label] ? true : false"
									:error-message="rti_validation_errors[field.mapping.label]"
									:hint="field.mapping.field_type"
									indicator-color="primary"
									active-color="primary"
								>
									<vue-tel-input
										:value="field.enabled ? field.prepopulated_value : ''"
										@input="updateField($event, 'prepopulated_value', index)"
										v-bind="bindProps"
										class="full-width q-pa-sm"
										:validCharactersOnly="true"
										:class="$q.screen.lt.sm ? 'small-view' : ''"
									>
									</vue-tel-input>
								</q-field>
									<!-- TEXT -->
								<q-input
									v-else-if="!field.mapping.field_type.includes('SELECT')"
									:value="field.enabled ? field.prepopulated_value : ''"
									@input="updateField($event, 'prepopulated_value', index)"
									:label="field.mapping.label"
									:type="
										field.mapping.field_type.includes('PHONE') ? 'tel' : 'text'
									"
									:disable="!field.enabled"
									:hint="field.mapping.field_type"
									:error="rti_validation_errors[field.mapping.label] ? true : false"
									:error-message="rti_validation_errors[field.mapping.label]"
								/>
								<!-- SELECT -->
								<q-select
									v-if="field.mapping.field_type.includes('SELECT')"
									:value="field.enabled
										? typeof field.prepopulated_value === 'string'
											? JSON.parse(field.prepopulated_value)
											: field.prepopulated_value
										: ''"
									@input="updateField($event, 'prepopulated_value', index)"
									:label="field.mapping.label"
									:multiple="field.mapping.field_type === 'MULTISELECT'"
									:disable="!field.enabled"
									:options="field.mapping.options"
									:hint="field.mapping.field_type"
									:option-label="(item) => item.value"
									clearable
								/>
							</div>
						</div>
						<div
							class="column col-12 col-sm-3 col-md-2 items-end q-my-sm"
							:class="$q.screen.gt.xs ? 'q-pl-md' : ''"
							:style="$q.screen.gt.xs ? 'border-left:1px solid #ddd;' : ''"
						>
							<div class="flex">
								<q-checkbox
									:value="field.enabled"
									@input="updateField($event, 'enabled', index)"
									label="Enabled"
									left-label
									:disable="
										field.mapping.field_type.includes('SELECT') &&
										field.mapping.options.length == 0
									"
								>
									<q-tooltip
										v-if="field.mapping.field_type.includes('SELECT') &&
											field.mapping.options.length == 0"
										anchor="top middle" :delay="500"
									>
										There are no available options for this field
									</q-tooltip>
									<br />
								</q-checkbox>
							</div>
							<div class="flex">
								<q-checkbox
									:value="field.required"
									@input="updateField($event, 'required', index)"
									label="Required"
									:disable="
										!field.enabled ||
										(
											instance.registration_type === 'SMS' &&
											!field.mapping.field_type.toLowerCase().includes('sms')) ||
										(
											field.mapping.field_type.includes('SELECT') &&
											field.mapping.options.length == 0
										)
									"
									left-label
								>
									<q-tooltip
										v-if="
											field.mapping.field_type.includes('SELECT') &&
											field.mapping.options.length == 0
										"
										anchor="top middle"
										:delay="500"
									>
										There are no available options for this field
									</q-tooltip>
								</q-checkbox>
							</div>
						</div>
					</div>
				</q-item-section>
			</q-item>
		</q-list>
	</div>
</template>

<script>
import DetailsButton from '@/components/datasets/edit/settings//DetailsButton.vue'
import { mapGetters } from 'vuex'

export default {
	components: {
		DetailsButton,
	},
	data() {
		return {
			instance_index: 0,
			bindProps: {
				mode: "international",
				defaultCountry: "GB",
				placeholder: "Enter a phone number"
			},
		}
	},
	computed: {
		...mapGetters(['rti_validation_errors']),
		instance: function () {
			const instance =
				this.$store.getters.registration_template_instances[this.instance_index]
			const devices = []
			const data_fields = []
			for (let field of instance.fields) {
				if (
					field.mapping.field_type.toLowerCase().includes('phone') ||
					field.mapping.field_type.toLowerCase().includes('email')
				) {
					devices.push(field)
				} else {
					data_fields.push(field)
				}
			}
			instance.fields = devices.concat(data_fields)
			return instance
		},
	},
	methods: {
		smsFieldSetter( prop, index){
			if (this.instance.registration_type === 'SMS'){
				for (let item in this.instance.fields){
					if (item !== index &&
						this.instance.fields[item].mapping.field_type.toLowerCase().includes('sms')
						){
						this.instance.fields[item][prop] = false
						this.$store.commit('updateRTIField', {
							value: false,
							prop,
							instance_index: this.instance_index,
							field_index: item,
						})
					}
				}
			}
		},
		updateField(value, prop, index) {
			if (prop === 'required'){
				this.smsFieldSetter(prop, index)
			}
			this.$store.commit('updateRTIField', {
				value,
				prop,
				instance_index: this.instance_index,
				field_index: index,
			})
		},
		getOptions(field) {
			return field.mapping.options.map((it) => ({
				value: it.id,
				label: it.value,
			}))
		},
		getInfoText() {
			return (
				'To pre-populate a field with a specific value ' +
				'please enter the value in the empty box below the field name. ' +
				'Fields that are not pre-populated, and which do not relate to phone numbers or ' +
				'email addresses will be marked as “Required” automatically. ' +
				'When a user ' +
				(this.instance.registration_type == 'SMS'
					? 'scans your QR code '
					: 'visits your registration page ') +
				'they will not be able to see any fields that have been pre-populated, ' +
				'will be prompted to fill in all required fields, and will be able to complete optional fields.'
			)
		},
	},
	created() {
		this.instance_index = this.$route.params.instance_index
	}
}
</script>

<style>
.vue-tel-input input {
	background-color: #f5f5f5;
}

.vue-tel-input:focus-within{
	border-color: var(--primary-color);
	border-width: 2px;
	box-shadow: inset 0 0 0
}

.small-view .vti__dropdown .vti__dropdown-list{
	width: auto!important;
}
</style>
